$colorPrimary: #429398;
$colorSecondary: #121212;
$colorBlack: #292929;

$colorInfo: #37d5f2;
$colorSuccess: #4fd69c;
$colorDanger: #f75676;
$colorWarning: #fc7c5f;

$boxShadow: 0px 10px 10px rgba(#000, 0.05);
