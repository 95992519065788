@import '../../Styles/Mixin.scss';

#monitoramento-social {
    .filter {
        display: flex;
        margin-bottom: 20px;
        border-bottom: 2px solid rgba(#000, 0.1);
        button {
            background: transparent;
            border: none;
            outline: none;
            padding: 10px 20px;
            margin-bottom: -2px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom: 2px solid $colorPrimary;
                color: #666;
            }
        }
    }
    .search-social {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        .social-list {
            padding: 10px 0px;
            display: flex;
            justify-content: space-between;
            width: 250px;
            button {
                background-color: rgba(#fff, 0.8);
                height: 45px;
                width: 45px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1),
                    0 1px 0 rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                svg {
                    width: 30px;
                    height: 18px;
                    path,
                    g {
                        fill: rgba(#000, 0.2);
                    }
                }
                &.active {
                    background-color: $colorSuccess;
                    path,
                    g {
                        fill: #fff;
                    }
                }
            }
        }
        .input-container {
            flex: 1;
            display: flex;
            align-items: center;
            width: calc(100% + 20px);
            margin: 0px -10px;
            .btn-icon {
                min-height: 45px;
                height: 45px;
                color: #000;
                background-color: $colorInfo;
                padding: 0px 15px;
                font-size: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1),
                    0 1px 0 rgba(0, 0, 0, 0.1);
                border: 0;
                border-radius: 4px;
                outline: none;
                cursor: pointer;
            }
        }
    }
    .title-post {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        svg {
            color: $colorBlack;
        }
        h3 {
            padding: 2px 0 0 5px;
            margin: 0px;
            color: $colorBlack;
        }
    }
}
