.chart-sentimental {
    .data {
        display: flex;
        margin-bottom: 30px;
        gap: 15px;
        .item {
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            .header {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                svg {
                    margin-right: 5px;
                }
                p {
                    margin: 0px;
                    color: #666;
                }
            }
            .legends {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                .legend {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                    i {
                        width: 10px;
                        height: 10px;
                        border-radius: 100px;
                        margin-right: 5px;
                    }
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
