@import '../../Styles/Mixin.scss';

#layout {
    display: flex;
    background: rgba(#000, 0.05);
    border-radius: 20px;
    .sidebar {
        width: 280px;
        height: 100vh;
        background: $colorBlack;
        box-sizing: border-box;
        transition: all 0.2s;
        @media (max-width: 500px) {
            position: fixed;
            z-index: 999;
        }
        .head {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(#fff, 0.1);
            padding: 0px 30px;
            .logo-default {
                display: flex;
                transition: all 0.5s;
                width: 100%;
                color: #fff;                
                font-size: 18px;
                box-sizing: border-box;
            }
            button {
                
                background: none;
                border: none;
                cursor: pointer;
                padding: 0px;
                
                svg {
                    color: rgba(#fff, 0.5);
                    font-size: 30px;
                    transition: all 3s;
                    
                }
            }
        }
        ul {
            list-style: none;
            padding: 30px;
            margin: 0 0 0 0;
            transition: all 0.2s;
            * {
                transition: all 0.2s;
            }
            li {
                margin-bottom: 10px;
                background: rgba(#fff, 0.05);
                border-radius: 5px;
                transition: all 0.2s;
                a {
                    text-decoration: none;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 5px;
                        font-size: 25px;
                        background: rgba(#000, 0.1);
                        border-radius: 4px;
                        padding: 5px;
                        min-width: 30px;
                        min-height: 30px;
                        width: 30px;
                        height: 30px;
                        box-sizing: border-box;
                        path {
                            fill: #fff;
                        }
                    }
                    span {
                        font-size: 13px;
                        color: #fff;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                &:hover {
                    background: rgba(#fff, 0.1);
                }
                &.active {
                    background: linear-gradient(
                        110deg,
                        $colorPrimary 0%,
                        $colorSecondary 100%
                    );
                }
            }
        }
        &.closed {
            width: 75px;
            .head {
                .logo-default {
                    margin-left: -200px;
                }
                button {
                    margin-right: -5px;
                }
            }
            ul {
                padding: 30px 10px;
            }
        }
    }
    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        position: relative;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        @media (max-width: 500px) {
            box-sizing: border-box;
            padding-left: 75px;
        }
        #content-header {
            padding: 0px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 60px;
            height: 60px;
            border-bottom: 1px solid rgba(#000, 0.1);
            &:after {
                width: 100%;
                height: 175px;
                content: '';
                background: colorPrimary;
                background: linear-gradient(
                    190deg,
                    $colorPrimary 0%,
                    $colorSecondary 100%
                );
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                transition: all 0.2s;
            }
            &.Dashboard {
                &:after {
                    height: 295px;
                }
            }
            &.RJ {
                &:after {
                    height: 320px;
                }
            }
            &.Automatizado {
                &:after {
                    height: 362px;
                }
            }
            &.SuperaRJ {
                &:after {
                    height: 320px;
                }
            }
            &.Nova {
                &:after {
                    height: 240px;
                }
            }
            h1 {
                font-size: 14px;
                margin: 0px;
                font-weight: 300;
                text-transform: uppercase;
                color: #fff;
            }
            .profile-user {
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 100px;
                    margin-right: 6px;
                }
                span {
                    font-size: 13px;
                    color: #fff;
                    font-weight: 300;
                }
            }
        }
        #content-page {
            flex: 1;
            padding: 0px 30px;
        }
    }
}
