@import '../../Styles/Mixin.scss';

#supera-rj {
    #chart {
        width: 100%;
        height: 259px;
        padding-top: 20px;
        box-sizing: border-box;
        padding-bottom: 20px;
        text {
            font-size: 13px;
        }
        .recharts-cartesian-axis-ticks {
            padding-top: 20px;
            margin-bottom: -10px;
        }
    }
    .table-default {
        margin-top: 40px;
    }

    .modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            margin: 40px;
            background: none;
            font-size: 40px;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
        .img {
            max-width: 90vw;
            max-height: 90vh;
            display: flex;
            img {
                transform: scalex(-1);
            }
        }
    }
}
